@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

.dropdown-profile-hover:hover {
  background-color: aliceblue;
  cursor: pointer;
  border-radius: 50%;
}

.NotesSave_btn_Iyqo_,
.notescancel_btn_nmZx_,
.NotesPanel_Title_IYqb {
  /* background-color:#fff !important; */
  color: #3c4b64 !important;
  border-radius: 0px !important;
}

.notification-hover:hover {
  background-color: aliceblue;
  cursor: pointer;
  border-radius: 50%;
  color: #66747F;
}

.NotesCard_OUtq {
  width: 100% !important;
  height: max-content !important;
  background-color: #fff !important;
}

.NotesCard_OUtqs {
  width: 100% !important;
  height: max-content;
  background-color: #fff !important;
  /* border-radius: 25px 25px 0px 0px !important; */
  border-radius: 25px!important;
}

.NotesTextArea_Ou_Qw {
  outline: none !important;
  background-color: rgb(238, 242, 245);
  border: none;
  border-radius: 15px;
  padding-top: 10px;
}

.add-notes-button-hover:hover {
  /* background-color: rgba(0, 0, 0, 0.04) !important; */
  cursor: pointer !important;
  color: #fff !important;
}

.add-notes-button-hover {
  border: none !important;
  text-transform: none !important;
  color: #fff !important;
  border-radius: 20px !important;
  font-family: 'Inter', sans-serif !important;
  font-weight: normal !important;
}

.HighlighterText_Tip_LmcX,
.Dictionarytext_Tip_YqmI,
.Notestext_Tip_UgXz,
.NotesPanel_Title_IYqb {
  background-color: #fff !important;

}

.Dictionarytext_Tip_YqmI:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
  cursor: pointer !important;
}

.HighlighterText_Tip_LmcX:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
  cursor: pointer !important;
}

.Notestext_Tip_UgXz:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
  cursor: pointer !important;
}

.vl {
  border-left: 1px solid #E6ECF1 !important;
  height: 80% !important;
}

.hl {
  width: 100%;
  border-bottom: 1px solid #E6ECF1;
  /* padding-top: 5px; */
  /* margin-bottom: 15px; */

}

.getClarifyData {
  cursor: pointer !important;
}

.profile-dropdown-container {
  position: absolute;
  /* top: 7%; */
  right: 7.5%;
  width: 200px;
  z-index: 999999;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 10px #00000029;
}

.school-title-navbar {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Inter', sans-serif;
}

.navbar-title {
  color: #9B36EA;
  font-size: 23px;
  /* font-weight: bold; */
  font-weight: 700;
  font-family: 'Ubuntu', sans-serif;
  display: flex;
  align-items: center;
}

.inter-font-300 {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
}

.inter-font-600 {
  font-family: 'Inter', sans-serif;
  font-weight: 600 !important;
}

.font-18-px {
  font-size: 18px !important;
}

.font-17-px {
  font-size: 17px !important;
}

.font-13-px {
  font-size: 14px !important;
}

.font-14-px {
  font-size: 14px !important;
}

.font-16-px {
  font-size: 16px !important;
}

.font-12-px {
  font-size: 12px !important;
}

.font-20-px {
  font-size: 20px !important;
}

.font-normal-style {
  font-style: normal !important;
}

.color-66747F {
  color: #66747F;
}

.color-1B252F {
  color: #1B252F !important;
}

.color-FF3B3B {
  color: #FF3B3B;
}

.color-35414D {
  color: #35414D;
}

.color-06C270 {
  color: #06C270;
}

.color-4C88FF {
  color: #4C88FF;
}

.orange-bullet {
  color: coral;
  font-size: 30px;
}

.orange-bullet-sm-cl {
  color: coral;
  font-size: 20px;
  margin-top: -4px;
}

.green-bullet-sm {
  color: green;
  font-size: 20px;
  margin-top: -4px;
}

.green-bullet-sm-cl {
  color: green;
  font-size: 20px;
  margin-top: -4px;
}

.green-bullet {
  color: green;
  font-size: 30px;
}

.color-FFFFFF {
  color: #FFFFFF !important;
}

.navbar-container {
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  background-color: #FFFFFF;
  /* position: fixed; */
  /* top:0; */
  width: 100%;
  border-bottom: 1px solid #EEF2F5;
}

.navbar-container-sub-container {
  border-top: 1px solid #EEF2F5;
  background-color: #fff;
  /* position: fixed; */
  /* top:6.8%; */
  width: 100%;
  border-bottom: 1px solid #EEF2F5;
  margin-right: 0% !important;
  display: block;
  padding: 0.3%;
}

.dropdown-profile-title {
  font-size: 16px;
  font-weight: bold;
  padding: 3%;
  text-align: center;
  border-bottom: 1px solid #838383;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Inter', sans-serif;
}

.HighlighterText_Tip_LmcX,
.Dictionarytext_Tip_YqmI,
.Notestext_Tip_UgXz {
  background-color: rgb(53, 65, 77) !important;

}

.Dictionarytext_Tip_YqmI:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
  cursor: pointer !important;
}

.HighlighterText_Tip_LmcX:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
  cursor: pointer !important;
}

.Notestext_Tip_UgXz:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
  cursor: pointer !important;
}

.BTTTN {
  box-shadow: none !important;
  letter-spacing: 0.7px;
}

.BTTTN:active {
  box-shadow: none !important;
}

.vl {
  border-left: 1px solid #E6ECF1;
  height: 80%;
}

.hl {
  width: 100%;
  border-bottom: 1px solid #E6ECF1;
  /* padding-top: 5px; */
  /* margin-bottom: 15px; */

}

.Clarify_Btn_JQu {
  background: #3377FF !important;
  border-radius: 30px !important;
  box-shadow: none !important;
  font-family: 'Inter' !important;
  width: max-content !important;
  text-transform: capitalize !important;
  color: #fff !important;
  font-family: Inter !important;
  /* display: flex!important; */
  -webkit-align-items: center;
  align-items: center;
  text-align: center !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}

.Clarify_AdBtn_JQu {
  background: linear-gradient(90deg, #1652C9 -0.74%, #002E8C 102.6%);
  border-radius: 30px !important;
  box-shadow: none !important;
  font-family: 'Inter' !important;
  /* width: 70%!important; */
  text-transform: capitalize !important;
  padding: 6px 30px !important;
  font-size: 15px !important;
  margin-top: 10px;
    color: rgb(255, 255, 255)!important;
    margin-bottom: 10px;
}

.Clarify_AdBtn_JQu_expand {
  background: linear-gradient(90deg, #1652C9 -0.74%, #002E8C 102.6%);
  border-radius: 30px !important;
  box-shadow: none !important;
  font-family: 'Inter' !important;
  width: max-content!important;
  text-transform: capitalize !important;
  color: #fff!important;
  padding: 6px 22px!important;
  margin-top: 5px!important;
  
}

.bookM_func_OPQ_addit {
  display: none !important;
}

.school-title-navbar {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Inter', sans-serif;
}

.navbar-title {
  color: #9B36EA;
  font-size: 23px;
  /* font-weight: bold; */
  font-weight: 700;
  font-family: 'Ubuntu', sans-serif;
  display: flex;
  align-items: center;
}

.inter-font-300 {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
}

.inter-font-500 {
  font-family: 'Inter', sans-serif !important;
  font-weight: 500 !important;
}

.inter-font-600 {
  font-family: 'Inter', sans-serif;
  font-weight: 600 !important;
}

.font-18-px {
  font-size: 18px !important;
}

.font-14-px {
  font-size: 14px !important;
}

.font-16-px {
  font-size: 16px !important;
}

.font-12-px {
  font-size: 12px;
}

.font-20-px {
  font-size: 20px !important;
}

.font-normal-style {
  font-style: normal !important;
}

.color-66747F {
  color: #66747F;
}

.color-1B252F {
  color: #1B252F !important;
}

.color-FF3B3B {
  color: #FF3B3B;
}

.color-35414D {
  color: #35414D;
}

.color-06C270 {
  color: #06C270;
}

.color-4C88FF {
  color: #4C88FF;
}

.orange-bullet {
  color: coral;
  font-size: 30px;
}

.orange-bullet-sm {
  color: coral;
  font-size: 20px;
  margin-top: -4px;
}



.green-bullet {
  color: green;
  font-size: 30px;
}

.color-FFFFFF {
  color: #FFFFFF !important;
}

.mainHeader_IQo {
  position: fixed;
  top: 0px;
  z-index: 999999;
}

.navbar-container {
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
  background-color: #FFFFFF;
  /* position: fixed; */
  /* top:0; */
  width: 100%;
  border-bottom: 1px solid #EEF2F5;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.Subhead_start_view {
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.Main_head_katt {
  padding: 10px;
}

.border-radius-bottom {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.12); */
}

.navbar-container-sub-container {
  border-top: 1px solid #EEF2F5;
  background-color: #fff;
  /* position: fixed; */
  /* top:6.8%; */
  width: 100%;
  border-bottom: 1px solid #EEF2F5;
  margin-right: 0% !important;
  display: block;
  padding: 0.3%;
}

.dropdown-profile-title {
  font-size: 16px;
  font-weight: bold;
  padding: 3%;
  text-align: center;
  border-bottom: 1px solid #838383;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Inter', sans-serif;

}

.dropdown-profile {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.dropdown-profile-sub-title {
  font-size: 14px;
  padding: 2%;
  display: flex;
  flex-direction: row;
  padding-left: 10%;
  padding-top: 3%;
  padding-bottom: 3%;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dropdown-profile-sub-title:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.notes-dropdown-hover:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.dropdown-profile-sub-title-name {
  padding-left: 3%;
  font-weight: 400;
}

/* .secondary-navbar-left-pane{
    /* display: flex;
    flex-direction: row;
    align-items: center;
  } */
.secondary-navbar-left-pane-items {
  cursor: pointer;
  margin: 0px 5px;
  display: flex;
  align-items: center;
  /* padding: 3px 10px; */
  /* padding: 10px 10px; */
  padding: 8px 10px;
  border-radius: 5px;

}

.secondary-navbar-left-pane-icons:hover {
  color: #000 !important;
}

.secondary-navbar-left-pane-icons {
  width: 20px;
  height: 20px;
}

.zoom-remove-border-color {
  border: none !important;
}

.seconary-navbar-icons {
  width: 20px !important;
  height: 20px !important;

}

.background-4d88ff {
  background-color: #4d88ff;
}

.background-fff {
  background-color: #fff;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  border: 1px solid #dfdcdc;
}

.none-select {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cursor-hover:hover {
  cursor: pointer;
}

.second-navbar-show-sessions {
  /* top: 15.5%; */
  right: 18%;
  border-radius: 10px;
  background-color: #fff;
  width: 300px;
}

.notes-input-area::placeholder {
  font-size: 14px;
  font-family: 'Inter', sans-serif;
}

.notes-input-area {
  font-size: 14px;
  font-family: 'Inter', sans-serif;
}

.icon-button-pointer:hover {
  cursor: pointer;
}

/* .mob-display-none {
  display: block !important;
}

.show_more_mobile{
  display: none;
} */


.css-1vm0r4s-MuiPaper-root {
  background-color: #fff;
  color: #ADB9C7 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50px !important;
  box-shadow: 0px 2px 12px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
  padding: 2px 4px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 390px !important;
}

.cardf {
  width: 100%;
  /* height: 100%; */
  top: 5px;
  /* border-radius: 20px !important; */
  overflow: auto;
  overflow-x: hidden;
  cursor: context-menu !important;
  /* background-color: #fff !important; */
  margin-bottom: 0px !important;
}

.Search_box_Icon_QW {
  border-radius: 50%;
  overflow: visible;
  background-color: initial;
  color: rgba(0, 0, 0, 0.54);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 10px;
  border: none !important;
}

.h5,
h5 {
  font-size: 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  cursor: auto !important;
}

.card-title {
  margin-bottom: 0.5rem;
  color: #3377FF;
  cursor: auto !important;
}

.cardtextpara {
  font-size: 14px;
  color: #93979d !important;
  cursor: auto !important;
}

.cardtextpara1 {
  /* display: flex !important;
  font-size: 14px;
  width: 100% !important;
  color: #93979d !important;
  cursor: auto !important;
  margin-left: 30px;
  margin-top: -20px; */

  display: flex !important;
  font-size: 14px;
  width: 90% !important;
  color: #93979d !important;
  cursor: auto !important;
  float: left;
  margin-left: 2%;
  margin-bottom: 10px;

}

.cardtextpara2 {
  display: flex !important;
  font-size: 14px;
  width: 90% !important;
  color: #93979d !important;
  cursor: auto !important;
  float: left;
  margin-bottom: 10px;
}

.BookM_Icon {
  float: left;
}

.textc {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #35414D;
  cursor: auto !important;
  width: 100%;
}

.textc1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #35414D;
  display: flex;
  cursor: auto !important;
}

.IconButton {
  cursor: context-menu !important;
}


/* width */

.cardf::-webkit-scrollbar {
  width: 0px !important;
  height: 2px !important;
}

.cardf::-webkit-scrollbar-track {
  max-height: 5px !important;
  height: 5px !important;
}

.cardf::-webkit-scrollbar-thumb {
  max-height: 5px !important;
  height: 5px !important;
}

.cardf::-webkit-scrollbar-thumb:hover {
  max-height: 5px !important;
  height: 5px !important;
}

/* ::-webkit-scrollbar {
  width: 0px;
  height: 10px !important;
  min-height: 10px !important;
  max-height: 10px !important;
} */

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  max-height: 5px;
  height: 5px;
}

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #888;
} */

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.formas {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  /* box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%); */
  padding: 2px 4px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* margin-top: 0.5em; */
  background-color: #fff;
  color: #ADB9C7 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 25px 25px 0px 0px !important;
  box-shadow: 0px 2px 12px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
  padding: 2px 4px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* width: 390px !important; */
  width: 100% !important;
}

.formas1 {
  /* background-color: #fff; */
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  padding: 2px 4px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 0.5em;
  margin-left: 20px;
  /* background-color: #fff; */
  color: #ADB9C7 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50px !important;
  box-shadow: 0px 2px 12px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
  padding: 2px 4px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* width: 390px !important; */
  width: 90% !important;
}

.css-i4bv87-MuiSvgIcon-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

img,
svg {
  vertical-align: middle;
}

svg:not(:root) {
  overflow: hidden;
}

.css-8je8zh-MuiTouchRipple-root {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.css-piqts5-MuiInputBase-root {
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 8px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; */

  font-family: 'Inter';
  font-weight: 400;
  font-size: 15px;
  /* line-height: 1.4375em; */
  letter-spacing: 0.00938em;
  color: #000;
  /* box-sizing: border-box; */
  /* position: relative; */
  /* cursor: text; */
  /* display: -webkit-inline-flex; */
  /* display: inline-flex; */
  /* -webkit-align-items: center; */
  /* align-items: center; */
  /* margin-left: 8px; */
  -webkit-flex: 1;
  flex: 1 1;
  border: none !important;
  border-color: #fff !important;


}



.css-yz9k0d-MuiInputBase-input:focus {
  outline: none !important;



}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.css-1gtpq4r-MuiButtonBase-root-MuiIconButton-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 10px;
}

.css-i4bv87-MuiSvgIcon-root {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  cursor: context-menu;
}


.searchIcons {
  margin-left: 220px !important;
  margin-top: 15px !important;
  cursor: pointer !important;
}

.closeIcons {
  margin-left: 35px !important;
  margin-top: 15px !important;
}

.closeIcons1 {
  cursor: pointer !important;
}

.css-i4bv87-MuiSvgIcon-root1 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  background-color: #fff;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  cursor: pointer;
}

.nameBook {
  font-size: 20px;
  font-weight: 500;
  color: rgb(102, 116, 127);
  font-family: Inter;
}

/* .naemBokk{
  display: flex;
  align-items: center;
} */

.bookiconsa2 {
  margin-left: 20px;
  font-weight: 600;
  height: 24px;
  size: 70;
  font-size: 20px;
  margin-top: 12px !important;
  color: #66747F;
  cursor: pointer;
}

.dictionaryIcons {
  width: 30px;
  height: 30px;
}

.caedf {
  width: 100%;
  height: 570px;
  border-radius: 20px !important;
  overflow: auto;
  overflow-x: hidden;
  cursor: context-menu !important;
}

.caedf::-webkit-scrollbar {
  width: 0px !important;
}

.flexicons {
  margin-top: 10px;
}

.volumeIcons {
  margin-left: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}


/* For Index Styles */

.Index {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.index_icon_color {
  color: #66747F;
}

.zoom_icon_color {
  color: #66747F;
}

.IndexMenuL {
  overflow-y: auto;
}

/*
.card{
  width: 100%;
  height: 750px;
  overflow-y: scroll;
  overflow-x: hidden;
  } */


.KB_SSQEx_SEARCH_MODULE .MuiAccordionSummary-content p {
  font-family: Inter !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px !important;
  margin-bottom: 0px !important;
  color: #35414D !important;
  font-weight: 500 !important;
  letter-spacing: normal !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-focusVisible {
  background-color: #fff !important;
}

.arrow {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  font-family: 'inter', sans-serif;
  font-weight: 500;
}

.session {
  /* padding:0px; */
  /* padding-left: 13%; */
  /* margin-left: 5%; */
  margin-bottom: 0px;
  cursor: pointer;
  padding: 5px 25px 5px 25px;
}

.clarify_dot {
  height: 8px;
  width: 8px;
  background-color: #06C270;
  border-radius: 50%;
  display: inline-block;
  transform: rotate(-0.19deg);
}

.session_Cstatus_dot {
  height: 8px;
  width: 11px;
  background-color: #06C270;
  border-radius: 50%;
  display: inline-block;
  -webkit-transform: rotate(-0.19deg);
  transform: rotate(-0.19deg);
  margin-top: 8px;
}

.session_Istatus_dot {
  height: 8px;
  width: 11px;
  background-color: #eab308;
  border-radius: 50%;
  display: inline-block;
  -webkit-transform: rotate(-0.19deg);
  transform: rotate(-0.19deg);
  margin-top: 8px;
}

.unanswered_dot {
  height: 8px;
  width: 8px;
  background-color: #FC5F37;
  border-radius: 50%;
  display: inline-block;
  transform: rotate(-0.19deg);
}

.session h7 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  color: #35414D;
  font-size: 14px;
}

.DictionCard {
  background-color: #fff !important;
  outline: 0 !important;
  height: 100% !important;
  width: 100%;
  border-radius: 25px !important;
  /* padding: 15px; */
}

.dicn_result {
  padding: 15px;
}

.bookM_checked {
  width: 30px;
  height: 30px;
  background-color: #EEF2F5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.bookM_unchecked {
  width: 30px;
  height: 30px;
  background-color: #EEF2F5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.bookM_text {
  margin-left: 10px;
  font-family: 'Inter';
  font-size: 14px;
  width: max-content !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}

/* width */

.card::-webkit-scrollbar {
  width: 0px !important;
}

/* Track */

.card::-webkit-scrollbar-track {
  background: #fff !important;
}

.card::-webkit-scrollbar-track:hover {
  background: #fff !important;
}

/* Handle */

.card::-webkit-scrollbar-thumb {
  background: #676565 !important;
  border-radius: 6px !important;
}

.footer-image {
  display: flex;
  justify-content: space-evenly;
}

.footer-image2 {
  display: flex;
  justify-content: flex-start;
}

.clarified {
  font-size: 13px;
  display: flex;
  justify-content: flex-start;
  padding: 15px;
  font-family: 'Inter';
}

.bullet1 {
  list-style: none;
}

.bullet1::before {
  content: "\2022";
  color: green;
  font-weight: bold;
  display: inline-block;
  /* width: 1em; */
  /* margin-left: -1em; */
}

.bullet2 {
  list-style: none;
}

.bullet2::before {
  content: "\2022";
  color: red;
  font-weight: bold;
  display: inline-block;
  /* width: 1em;
  margin-left: -1em; */
}

/* for Index style */


/* for Notes style */
.ul {
  display: flex;
  justify-content: space-evenly;
  padding: 12px;
}

.ul_clarify_data_count {
  display: flex;
  justify-content: space-evenly;
  padding: 5px 15px;
}

.session .ul {
  margin-bottom: 0px;
  float: left;
  padding-left: 0px;
  font-size: 14px;
  font-family: 'Inter';
}

.notes_dot {
  /* display: none !important;
    display: none !important; */
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
  padding: 5px 10px 10px 0px;
}

.session_dot {
  height: 7px;
  width: 7px;
  background-color: grey;
  border-radius: 50%;
  display: inline-block;
}


.foc {
  cursor: pointer;
  color: grey;
}

/* width */
/* ::-webkit-scrollbar {
  width: 0px !important;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: #f1f1f1;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #888;
} */

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.bullet {
  /* list-style: none; */
  color: grey;

}

/* .bullet::before{
  content: "\2022";
color: grey;
font-weight: bold;

} */

.inputbox {
  width: 95%;
  height: 100%;
  border-radius: 30px;
  border: none;
  background-color: #EEF2F5;
  outline: none;
  position: relative;
  padding: 20px;
}

/* .AttachImage {
position: absolute;
margin-left: -47px;
margin-top: 16px;
} */


.para {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  color: #35414D;
  font-size: 14px;
  Line-height: 21px;
}

.heading {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  Line-height: 24px;
}

.show {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* cursor: pointer; */
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
}

.st_notes {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #35414D;
  align-items: center;
  text-align: center;
}

.p_highlight {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.align_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 70vh;
}

.card-body {
  padding: 0px !important;
}

.padding-15 {
  padding: 15px;
}






.borderbott {
  border-bottom: 1px solid #d9cdcd;
  width: 100vw;
  margin-bottom: 25px;
  margin-top: -10px;
}

b {
  color: #3377FF;
  font-size: 16px;
  font-weight: 600;
}

/* for Search and Bookmark style */


/* jwplayer style start */

.jw-display .jw-icon {
  margin-left: -202px !important;
  margin-top: 200px !important;
  width: 50px !important;
  height: 50px !important;
}

.jwplayer .jw-display-icon-container {
  cursor: pointer !important;
}

.jwplayer .jw-media video {
  cursor: context-menu !important;
}

.jw-title-primary,
.jw-title-secondary {
  display: none !important;
}

video .jw-video .jw-reset {
  border-radius: 50px !important;
}

.jwplayer {
  border-radius: 15px !important;
}

.jw-icon-pip,
.jw-logo-button,
.jw-icon-rewind,
.jw-svg-icon-buffer {
  display: none !important;
}

#TAITbudl .jw-button-color.jw-toggle:not(.jw-icon-cast),
#TAITbudl .jw-button-color:hover:not(.jw-icon-cast),
#TAITbudl .jw-button-color:focus:not(.jw-icon-cast),
#TAITbudl .jw-button-color.jw-toggle.jw-off:hover:not(.jw-icon-cast) {
  color: #ffffff !important;
}

.jwplayer.jw-state-complete .jw-display .jw-icon-display .jw-svg-icon-replay {
  display: none !important;
}

.jw-state-idle .jw-svg-icon-play path {
  display: none;
}

.jw-state-idle .jw-svg-icon-play {
  background-image: url("../../assets/Group\ 6586.png") !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  cursor: pointer !important;
}

/* jwplayer style end */

.self_asst_submit_main {
  width: 98%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.self_asst_submit_main .self_submit_button {
  color: #fff;
  font-family: 'Inter';
  font-style: normal;
  font-size: 15px;
  line-height: 24px;
  background-color: #3377ff;
  border: 0px;
  padding: 5px 25px;
  float: right;
  border-radius: 20px;
}



/* Note Filter Jai */
.Filter_main {
  margin-top: 5px;
  padding-left: 20px;
}

.Filter_main_x {
  margin-top: 5px;
  cursor: pointer;
}

.Filter_mainx {
  margin-top: 5px;
  padding-right: 20px;
}

.Unit_Style {
  margin-left: 10px;
}

.filter_Font {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

}

.filter_Font_x {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0.001em;
  text-decoration-line: underline;
  color: #1D1D1D;

}

.session_style_filter_xyzsa {
  width: 75px;
  height: 24px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #35414D;
}

.session_style_filter_xyzsaell {
  height: 24px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #35414D;
}

.session_style_filter_xyzsaall {
  height: 24px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #35414D;
  padding-top: 5px;
}

.session_styleX {
  margin-top: 12px;
  padding-left: 38px;
}

.filter_Cancel {
  color: #66747F;
  padding-top: 10px;
  cursor: pointer;
}

.filter_Apply {
  width: 119px;
  height: 45px;
  background: #3377FF;
  color: #fff;
  border-radius: 30px;
  padding-top: 10px;
  padding-left: 40px;
  margin-right: 10px;
}

.padding_xFilter {
  padding-bottom: 10px;
}

/* Note Filter end */

/* video player usePlatform start Jai */

.containerandrid {
  justify-content: center;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  flex-direction: row;
  flex-wrap: nowrap !important;
}

.containerandrid::-webkit-scrollbar {
  display: none;
}

.containerandrid {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* video player usePlatform end Jai */

/* language jai */

.engsubjai {
  position: absolute;
  width: 109px;
  height: 51px;
  left: 24px;
  top: 80px;
  color: #35414D;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}

.secengjai {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */


  /* Grey 2 */

  color: #35414D;

  position: absolute;
  width: 48px;
  height: 21px;
  left: 24px;
  top: 110px;

}

.engsubtickjai {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 327px;
  top: 93px;
}

.hinsubjai {
  position: absolute;
  width: 31px;
  height: 24px;
  left: 24px;
  top: 163px;

  font-family: 'Hind';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */


  /* Grey 2 */

  color: #35414D;
}

.secehinjai {
  position: absolute;
  width: 34px;
  height: 21px;
  left: 24px;
  top: 193px;

  /* B3-14px/Body 3-R */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */


  /* Grey 2 */

  color: #35414D;
}

.tellanjai {
  position: absolute;
  width: 45px;
  height: 24px;
  left: 24px;
  top: 246px;

  font-family: 'Hind';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */


  /* Grey 2 */

  color: #35414D;

}

.telsecjai {
  position: absolute;
  width: 45px;
  height: 21px;
  left: 24px;
  top: 276px;

  /* B3-14px/Body 3-R */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */


  /* Grey 2 */

  color: #35414D;

}

.tamillanjai {
  position: absolute;
  width: 45px;
  height: 24px;
  left: 24px;
  top: 329px;

  font-family: 'Hind';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */


  /* Grey 2 */

  color: #35414D;
}

.tamsecjai {
  position: absolute;
  width: 35px;
  height: 21px;
  left: 24px;
  top: 359px;

  /* B3-14px/Body 3-R */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */


  /* Grey 2 */

  color: #35414D;

}

.kanlanjai {
  position: absolute;
  width: 35px;
  height: 24px;
  left: 24px;
  top: 412px;

  font-family: 'Hind';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */


  /* Grey 2 */

  color: #35414D;
}

.kansecjai {
  position: absolute;
  width: 58px;
  height: 21px;
  left: 24px;
  top: 442px;

  /* B3-14px/Body 3-R */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */


  /* Grey 2 */

  color: #35414D;

}


.mallanjai {
  position: absolute;
  width: 69px;
  height: 24px;
  left: 24px;
  top: 495px;

  font-family: 'Hind';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */


  /* Grey 2 */

  color: #35414D;
}

.mallansecjai {
  position: absolute;
  width: 71px;
  height: 21px;
  left: 24px;
  top: 525px;

  /* B3-14px/Body 3-R */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */


  /* Grey 2 */

  color: #35414D;

}


/* covrage report jai start */

.coveronejai {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
}

.covertwojai {
  width: 100%;
  background-color: #E5E5E5;
  border-radius: 50px;
  height: 8px;
  margin-left: 10px;
}

.coverthreejai {
  height: 8px;
  border-radius: 50px;
  background-image:
    linear-gradient(to right, #045DE9, #09C6F9);
}

.coverthreejai111 {
  height: 8px;
  border-radius: 50px;
  background-image:
    linear-gradient(to right, #11998e, #38ef7d);
}

.coverthreejai222 {
  height: 8px;
  border-radius: 50px;
  background-image:
    linear-gradient(to right, #045DE9, #09C6F9);
}

.coverthreejai333 {
  height: 8px;
  border-radius: 50px;
  background-image:
    linear-gradient(to right, #673ab7, #512da8);
}

.coverthreejai444 {
  height: 8px;
  border-radius: 50px;
  background-image:
    linear-gradient(to right, #fc4a1a, #f7b733);
}

.coverthreejai555 {
  height: 8px;
  border-radius: 50px;
  background-image:
    linear-gradient(to right, #fffc00, #fffc00);
}

.coverthreejai666 {
  height: 8px;
  border-radius: 50px;
  background-image:
    linear-gradient(to right, #fbd3e9, #bb377d);
}

.cover85perjai {
  margin-left: 10px;
  height: 18px;
  width: 25px;
  margin-top: -5px;
}

.covershowprogjai {
  color: #4C88FF;
  height: 21px;
  line-height: 21px;
  margin-left: 20px;
  width: 200px;
  margin-top: -5px;
  cursor: pointer;
}

.showCoverone {
  /* position: fixed;
  left: 25.5%;
  top: 42%;
  height: 590px;
  width: 327px;
  background-color: #fff;
  border: 1px solid #E6ECF1;
  border-radius: 10px;
  margin-top: -185px;
  z-index: 99999999;
  overflow: auto;
  box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px; */
  position: fixed;
  left: 25.2%;
  top: 10%;
  height: 590px;
  width: 327px;
  background-color: #fff;
  border-radius: 15px;
  z-index: 99999999;
  overflow: auto;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 0px;
}

.showProgressBody {
  border: 1px solid #05379b;
  border-radius: 0px 0px 10px 10px;
}

.showcovertwojai {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}

.showcoveraaijai {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #8F9CAA;
  margin-left: 8px;
  margin-top: 3px;
  width: 100%;
}

.coverflexonejai {
  width: 100%;
  background-color: #E5E5E5;
  border-radius: 50px;
  height: 8px;
  /* margin-left: 10px; */
  margin-top: 10px;
}

.bill50 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #35414D;
  margin-left: 12px;
  margin-top: 5px;
}

.bill_comments_posted {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #35414D;
  /* margin-left: 134px; */
}

.bill1hr {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #35414D;
  margin-left: 103px;
}

/* covrage report jai end */


/* udeductaed in subheader jai */

.flecueducate {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 20%;
  justify-content: flex-end;
  position: absolute;
  bottom: 50px;
}

.flexudutwo {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 0px;
  gap: 4px;

  width: 114px;
  height: 15px;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.powerdeduedu {
  width: 56px;
  height: 15px;

  /* Tiny Text - 10px/Tiny Text - R */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
  /* identical to box height, or 15px */


  /* Grey 4 */

  color: #8F9CAA;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.ueduclrjai {
  width: 54px;
  height: 14px;

  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  text-transform: capitalize;

  /* Primary Blue */

  color: #3377FF;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.udecte2323 {

  width: 124px;
  height: 15px;

  /* Tiny Text - 10px/Tiny Text - M */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 150%;
  /* identical to box height, or 15px */


  /* Grey 5 */

  color: #ADB9C7;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}


.footericonsubheadmob {

  box-sizing: border-box;
  position: absolute;
  width: 48px;
  height: 48px;
  /* top: 486px; */
  /* left: 325px; */
  right: 35px;
  bottom: 100px;
  background: #FFFFFF;
  border: 1px solid #E6ECF1;
  box-shadow: 0px 4px 23px rgb(0 0 0 / 12%);
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* udeductaed in subheader jai end */
/* Style for Notification  */

.Noti_Karthick {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #35414D;
}

.R_F_C {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #8F9CAA;
}

.Mins {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #8F9CAA;
}

.D_Flex {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.Class_XI {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ADB9C7;
}

.Content_H6 {
  display: flex;
  justify-content: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #35414D;
  margin-top: 10px;
  margin-left: 11%;
}

.Noti_Bell {
  background-color: #E6ECF1;
  border-radius: 50px;
  padding: 12px;
  color: #8F9CAA;
  width: 50px;
  height: 50px;
}

.Noti_U_T_2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #1B252F;
}

.View_R {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #3377FF;
  margin-top: 8px;
  cursor: pointer
}



/* style={{
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '18px',
  color: '#3377FF',
  marginTop:"8px",
  cursor:"pointer"
}} */
/* Style for Notification  */