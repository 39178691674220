.mob-display-flex{
  display:none !important;
}

/* Tablet */
@media (max-width: 1010px) {
  .InterSection_LeftTitle_INcX{
    justify-content:left !important;
  }
  .mob-display-contents-none{
    display : none !important;
  }
  .HeaderNavbar_school_tooltip{
    left: 1% !important;
    right: auto !important;

  }
  .HeaderNavbar_school_tooltip_tri{
    display: flex;
    justify-content: flex-start;
    margin-left: 8% !important;
  }
  .InterSection_LeftTitle_INcX_assessment{
    justify-content:left !important;
  }
  .mob-display-flex{
    display:flex !important;
  }
  .Mob_Notes{
    width: 100vw !important;
    height: 100vh !important;
    position: absolute !important;
    z-index: 999999 !important;
  }
  .NotesCard_OUtqs{
    width: 100% !important;
    height: 100vh !important;
    background-color: #fff!important;
  }

  /* Assessment  */
  .HeaderNavbar_school_tooltip{
    left: 1% !important;
    right: auto !important;

  }
  .HeaderNavbar_school_tooltip_tri{
    display: flex;
    justify-content: flex-start;
    margin-left: 8% !important;
  }
  .mob_bg_FFFFFF{
    background-color: #FFFFFF !important;
  }
  .asswss_mob_qus_dbsfkl{
    padding:25px 15px;
    border-radius:4px;
    width:100% !important;
  }
  .assesss_contain_ydsaasgu{
    background-color: #eef2f5 !important;
  }
  .assessment_fullcontainer{
      background-color: #eef2f5 !important;
      padding:0px !important;
  }

  .assessment_header_skdal{
    padding: 14px 10px !important;
    margin: 14px 0px !important;

  }
  .assessment_container_questionalignment_skdjh{
    margin-left: 20px;
  }
  .assessment_continer_align_dnjkdd{
    justify-content: flex-start !important;

  }
  .assessment_continer_taketestfont_dnjkdd{
    font-size: 13px !important;
  }
  .assessment_container_questaketest_skdjh{
    background: rgb(76, 136, 255);
    border-radius: 0px 0px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: max-content !important;
    padding: 8px 20px !important;
    border-radius: 18px !important;
    position: absolute !important;
    bottom: 10px !important;
    left: 20px;
  }
  .assessment_container_card_skdjh{
    flex-direction :column !important;
  }
  .assessment_container_card_skdjh_list{
    width:100% !important;
    position: relative;
    margin: 0px  0px 15px 0px !important;
  }
  /* Assessment End */

  .Clarify_Btn_JQu{
    width: max-content !important;
}
  .ChatList_Uybv71L{
    height : 68vh !important;
  }
  .CLARIFY_FDSSD > div{
    width:80vw !important;
  }

  .intersection_title{
    display: flex;
    justify-content: center !important;
    align-items: center;
    padding:20px 0px;
  }
  .intersection_title_assessment{
    display: flex;
    justify-content: center !important;
    align-items: center;
    padding:0px 0px;
  }
  /* .bookM_func_OPQ{
    display:none!important;
  } */
  .root_container_display{
    background-color:#eef2f5 !important;
    margin-top: 0 !important;
  }
  .InterSection_LeftTitle_INcX{
    min-width : 100% !important;
  }
  .InterSection_LeftTitle_INcX_assessment{
    min-width : 100% !important;
  }
  .show_more_mobile{
    display:flex !important;
    position:absolute !important;
    top: -40px !important;
    width:100vw !important;
    height:91vh !important;
    background-color:#FFFFFF !important;
  }

  .Definition_Mob_mode{
    display: flex !important;
    width: 100vw !important;
    height: 100vh !important;
    position: absolute !important;
    z-index: 999999 !important;
    background-color: #FFFFFF !important;
  }

  .VideoModule_UYqI_fold,.VideoModule_UYqI{
    width: 0 !important;
    padding: 0px !important;
  }
  .Book_notes_ListView{
    position: absolute !important;
    top: -60px !important;
    z-index: 20 !important;
    left:0 !important;
    right: 0 !important;
  }

  .all_clarify_view {
    position: absolute !important;
    top: -60px !important;
    z-index: 20 !important;
    left:0 !important;
    right: 0 !important;
  }
  .JFFIJFKF2,.Sear_Res_qwmM,.SEARCH_iyNB_Card,.Book_notes_ListView,.all_clarify_view,.ClariFy_React_Drag_res{
    width:100vw !important;
    height: 100vh !important;
  }


  .ClarifyView{
    top:0 !important;
  }
  .ClariFy_React_Drag_res{
    transform: translate(0px, 0px) !important;
  }
  .KB_SSQEx_SEARCH_MODULE{
    display:flex !important;
    flex-direction:column !important;
    position:absolute !important;
    width:100vw !important;
    height:100vh !important;
    background-color:#FFFFFF !important;
    /* top: 51px !important; */
    top: 0 !important;
    left: 0 !important;
  }
  .KB_SSQEx_SEARCH_MODULE .marginBottom-5{
    padding: 10px !important;
  }
  .KB_SSQEx_SEARCH_MODULE .marginBottom-5{
    padding: 10px !important;
  }
  .marginBottom-5 .formas{
    border-radius: 10px !important;
  }
  .SideModule_nav{
    left:0 !important;
  }
  .pqNy65_Hnmq{
    padding :0px !important;
  }
  .session_title_text{
    width:200px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  .align-third-container{
    display: flex !important;
    align-items: center !important;
    flex-wrap: wrap !important;
    justify-content: flex-end !important;
    width: min-content !important;
  }
  .session_title_text{
    width:130px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  .profile-dropdown-container {
    right: 2% !important;
  }
  .web-display-none {
    display:block !important;
  }
  .mob-display-none{
    display:none !important;
  }
  /* .mob-display{
  display:block !important;
  } */

  .secondary-navbar-left-pane-items {
    cursor: pointer !important;
    margin: 0px!important;
    /* margin:0px 20px 0px 10px !important; */
    /* display: flex !important; */
    align-items: center !important;
  }
  .second-navbar-show-sessions{
    /* top: 20% !important; */
    /* right: 1% !important;
     */
     left: 0% !important;
    border-radius: 10px !important;
    background-color: #fff !important;
    z-index: 11111111 !important;
    /* width:300px !important; */
    width:100vw !important;

    height: 60vh !important;
    overflow: overlay !important;
  }
  .second-navbar-show-sessions-items{
    font-size: 16px !important;
    color: #35414D !important;
    font-style: normal !important;
    font-family: 'Inter', sans-serif !important;
    font-weight:500 !important;

  }
  .second-navbar-show-sessions-items:hover{
    background-color: rgba(0, 0, 0, 0.04) !important;
    cursor: pointer !important;
  }

  .vl {
    display: none !important;
  }

  .dropdown-profile {
    display: none !important;
  }



  .school-title-navbar {
    font-size: 80% !important;
    font-weight: bold !important;
  }

  .navbar-title {
    color: #9B36EA !important;
    font-size: 20px !important;
    font-weight: bold !important;
  }

  .AddiContent_Text{
    padding : 0px 30px !important;
  }
  .ContentBIo_qa_,.ContentBook_Xv_fold,.root_container{
    width:96vw !important;
  }
  .align-third-container .secondary-navbar-left-pane-items,.m-secondary-navbar-left-pane-items{
    padding: 8px 0px !important;
  }

  .MobMode{
    width: 100vw !important;
    height: 100vh !important;
    position: absolute !important;
    z-index: 30 !important;
  }
}

/* Mobile */
@media only screen and (min-device-width: 280px) and (max-device-width: 908px) and (orientation:portrait) {
  .mob-display-contents-none{
    display : none !important;
  }
  .contentView_scroll img{
    max-width:100%;
  }
  .tooltip-name{
    font-size: 9px !important;
  }
  .ContentTooltip_Icon{
     top: 319px !important;
    left: 40% ;
  }
  .KB_SSQEx_SEARCH_MODULE .marginBottom-5{
    padding: 10px !important;
  }
  .marginBottom-5 .formas{
    border-radius: 10px !important;
  }
  .SideModule_nav{
    left:0 !important;
  }
  /* Assessment */
  .HeaderNavbar_school_tooltip{
    left: 1% !important;
    right: auto !important;

  }
  .HeaderNavbar_school_tooltip_tri{
    display: flex;
    justify-content: flex-start;
    margin-left: 8% !important;
  }
  .mob_bg_FFFFFF{
    background-color: #FFFFFF !important;
  }
  .asswss_mob_qus_dbsfkl{
    padding:25px 15px;
    border-radius:4px;
    width:100% !important;
  }
  .assesss_contain_ydsaasgu{
    background-color: #eef2f5 !important;
  }
  .assessment_fullcontainer{
    background-color: #eef2f5 !important;
    padding:0px !important;
}

  .assessment_header_skdal{
    padding: 14px 10px !important;
    margin: 14px 0px !important;

  }
  .assessment_continer_align_dnjkdd{
    justify-content: flex-start !important;
    margin-left: 20px;
  }
  .assessment_continer_taketestfont_dnjkdd{
    font-size: 13px !important;
}

  .assessment_container_questionalignment_skdjh{
    margin-left: 20px;
  }

  .assessment_container_questaketest_skdjh{
    background: rgb(76, 136, 255);
    border-radius: 0px 0px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: max-content !important;
    padding: 8px 20px !important;
    border-radius: 18px !important;
    position: absolute !important;
    bottom: 10px !important;
    left: 10px;
  }

  .assessment_container_card_skdjh{
    flex-direction :column !important;
  }
  .assessment_container_card_skdjh_list{
    width:100% !important;
    position: relative;
    margin: 0px  0px 15px 0px !important;

  }
  /* Assessment End */

  .Clarify_Btn_JQu{
      width: max-content !important;
  }
  .ChatList_Uybv71L{
    height : 68vh !important;
  }
  .CLARIFY_FDSSD >  div{
    width:80vw !important;
  }
  .intersection_title{
    display: flex;
    justify-content: center !important;
    align-items: center;
    padding:20px 0px;
  }
  .intersection_title_assessment{
    display: flex;
    justify-content: center !important;
    align-items: center;
    padding:0px 0px;
  }
  /* .bookM_func_OPQ{
    display:none!important;
  } */
  .root_container_display{
    background-color:#eef2f5 !important;
    margin-top: 0 !important;
  }
  .InterSection_LeftTitle_INcX{
    min-width : 100% !important;
  }
  .InterSection_LeftTitle_INcX_assessment{
    min-width : 100% !important;
  }
  .ContentBIo_qa_,.ContentBook_Xv_fold,.root_container{
    width:96vw !important;
  }
  .AddiContent_Text{
    padding : 0px 30px !important;
  }
  .VideoModule_UYqI_fold,.VideoModule_UYqI{
    width: 0 !important;
    padding: 0px !important;
  }
  .JFFIJFKF2{
    position: absolute !important;
    top: 0px !important;
    z-index: 20 !important;
  }
  .JFFIJFKF2,.ClariFy_React_Drag_res{
    width:100vw !important;
    height: 100vh !important;
  }
  .ClariFy_React_Drag_res{
    transform: translate(0px, 0px) !important;
  }
  .ClarifyView{
    top:0 !important;
  }

  /* jai */

  .Mob_Notes{
    width: 100vw !important;
    height: 100vh !important;
    position: absolute !important;
    z-index: 999999 !important;
  }


  .coverreport_mob_modfgh{
    width: 100vw !important;
    height: 100vh !important;
    left: 4px !important;
    position: absolute !important;
    z-index: 99999999999999999999999999999999 !important;
    background-color: #FFFFFF !important;
  }

  .Definition_Mob_mode{
    width: 100%!important;
    height: 100%!important;
    position: fixed !important;
    z-index: 999999 !important;
    background-color: #fff !important;
    transform: rotate(0deg)!important;
  }

  .MobMode{
    width: 100vw !important;
    height: 100vh !important;
    position: absolute !important;
    z-index: 999999 !important;
  }
/* jaiend */

.align-third-container{
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  /* justify-content: flex-end !important; */
  justify-content: center!important;
}

.tooltip {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.align-third-container .secondary-navbar-left-pane-items:nth-child(1), .align-third-container .secondary-navbar-left-pane-items:nth-child(2) {
  padding: 0px 15px!important;
}
.align-third-container .secondary-navbar-left-pane-items{
  padding: 8px 0px !important;
}

.m-secondary-navbar-left-pane-items{
  padding: 8px 10px !important;
}

  .Margin_Left_Card {
    width: 43% !important;
    padding: 0px 0px;
    margin: 0px 10px;
  }
  .web-display-none {
    display:block !important;
  }
  .mob-display{
  display:block !important;
  }
  .Booklist_Subject {
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 13px !important;
    color:#1B252F !important;

  }

  .Subject_container {
    padding:10px 3px !important;
    /* width:100vw !important; */
  }

  .Card_Book_List {
    height:260px !important;
  }

  .Manoj_span{
    font-size: 10px !important;
  }

  .myProgress{
    width:80% !important;
  }

  .myBar{
    height:7px !important;
  }

  .grennmyBar{
    height:7px !important;
  }

  .orangemyBar{
    height:7px !important;
  }

  .purplemyBar{
    height:7px !important;
  }

  .pinkmyBar{
    height:7px !important;
  }

  .Image_Size {
    width: 100%;
    height: 130px;
    margin-top: -25px;
  }

  .Book_U_PC {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* margin-top: 15px; */
    font-size: 9px;
    /* align-items: center; */
  }

  .BookOpen_Icon {
    width:14px;
    height:14px;
  }

  .Pad_Mar {
    padding: 15px 5px 0px 8px;
     margin-top: 5px;
 }

.session_title_text{
  width:100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.show_more_mobile{
  position:absolute;
  top: -40px;
  width:100vw;
  height:91vh;
  background-color:#FFFFFF;
}
.pqNy65_Hnmq{
  padding :0px!important;
}
.SideModule_nav{
  left:0;
}
.mob-display-none{
  display:none !important;
}

#tooltip {
  left:2%!important;
  /* display:none !important; */
  top: 150px!important;
}

}

/* Desktop */
@media screen and (min-width: 1000px){

  .mob-display-none {
  display: block !important;
}

.web-display-none {
  display:none !important;

}
.mob-display{
  display: none !important;
}

}




/* Responsive design for Mobile  Kalaichelvan*/

@media (min-width: 280px) and (max-width:1100px) and (orientation: portrait) {
  .KB_SSQEx_SEARCH_MODULE .marginBottom-5{
    padding: 10px !important;
  }
  .marginBottom-5 .formas{
    border-radius: 10px !important;
  }
  .SideModule_nav{
    left:0 !important;
  }
  .mob-display-flex{
    display:flex !important;
  }

  .Units_Count_Container {
    width: 46%!important;
}

  .Margin_Left_Card {
    width: 40%;
  }

  .Booklist_Subject {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color:#1B252F;
  }
  .Main_sub_Card {
    margin-top: 12%!important;
  }

  .Subject_container {
    padding:10px 3px;
    /* width:100vw;
    height:100vh; */
    overflow:auto;
  }

  /* .Card_Book_List {
    height:auto;
  } */
  .Units_Count_Text {
    /* margin-left: 25%; */
  }

  .Card_Book_List {
    margin-top: 40px!important;
    height: auto!important;
  }

  .Manoj_span {
    font-size: 10px;
  }

  .myProgress {
    width:75% !important;
  }

  .myBar {
    height:7px !important;
  }

  .grennmyBar {
    height:7px !important;
  }

  .orangemyBar {
    height:7px !important;
  }

  .purplemyBar {
    height:7px !important;
  }

  .pinkmyBar {
    height:7px !important;
  }

  .Image_Size {
    width: 72px;
    height: 116px;
    margin-top: -25px;
  }

  .Book_U_PC {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    font-size: 9px;
  }

  .BookOpen_Icon {
    width:14px;
    height:14px;
  }

  .Pad_Mar {
    padding: 0px 10px;
     margin-top: 5px;
  }

  .web-display-none {
    display:contents;
  }

  .mob-display-none {
    display:none;
  }

  .School_Tooltip {
    left: 1%;
    max-width: 300px;
  }

  .School_Tooltip_Cube {
    display:flex;
    justify-content:flex-end;
    margin-right: 85%;
  }

  .ContentTooltip_Icon {
    left:15% !important;
    width:max-content !important;
  }

  .font-12-px {
    font-size: 6px !important;
  }

}


/* @media (min-width: 280px) and (max-width:1000px) and (orientation: landscape) {

  .Margin_Left_Card {
    width: 42%;
  }

  .Subject_container {
    padding:10px 3px;
    width:100vw;
    height:100vh;
    overflow:auto;
  }

  .Card_Book_List {
    height:auto;
  }
} */

/* Responsive design for Mobile  */


/* Responsive design for Tablet  */

@media (min-width: 481px) and (max-width: 900px) and (orientation: portrait) {
  .KB_SSQEx_SEARCH_MODULE .marginBottom-5{
    padding: 10px !important;
  }
  .marginBottom-5 .formas{
    border-radius: 10px !important;
  }
  .SideModule_nav{
    left:0 !important;
  }
  .mob-display-flex{
    display:flex !important;
  }
  .InterSection_LeftTitle_INcX{
    justify-content:left !important;
  }
  .InterSection_LeftTitle_INcX_assessment{
    justify-content:left !important;
  }
  .Margin_Left_Card {
    width: 27%;
  }

  .Booklist_Subject {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color:#1B252F;
  }

  .Image_Size {
    width: 85px;
    height: 130px;
  }


  .Book_U_PC {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
  }

  .BookOpen_Icon {
    width:12px;
    height:12px;
  }

  .Pad_Mar {
    padding: 15px 5px 0px 10px;
     /* margin-top: 5px; */
  }

  .School_Tooltip {
    left: 1%;
    max-width: 300px;
  }

  .School_Tooltip_Cube {
    display:flex;
    justify-content:flex-end;
    margin-right: 85%;
  }

  .Subject_container {
    padding:10px 0px 0px 40px;
    width:100vw;
    height:92vh;
    overflow:auto;
  }

  /* .Card_Book_List {
    height:auto;
  } */

  .Manoj_span {
    font-size: 10px;
  }

  .myProgress {
    width:80% !important;
  }

  .myBar {
    height:7px !important;
  }

  .grennmyBar {
    height:7px !important;
  }

  .orangemyBar {
    height:7px !important;
  }

  .purplemyBar {
    height:7px !important;
  }

  .pinkmyBar {
    height:7px !important;
  }

  .Card_Book_List {
    height:75%;
  }

  .Pad_Mar {
    padding: 5px 10px 0px 10px;
  }

}

@media (min-width: 481px) and (max-width: 1200px) and (orientation: landscape) {


  .NotesCard_OUtqs{
    width: 100% !important;
    height: 100vh !important;
    background-color: #fff!important;
  }

  .Main_sub_Card {
    margin-top: 4%!important;
  }

  .Margin_Left_Card {
    width: 25%;
  }

  .Definition_Mob_mode{
    width: 100%!important;
    height: 100%!important;
    position: fixed !important;
    z-index: 999999 !important;
    background-color: #fff !important;
    transform: rotate(0deg)!important;
  }

  .Units_Count_Container {
    width:45%;
  }

  /* .Image_Size {
    width: 85px;
    height: 130px;
  } */

  .Subject_container {
    padding:10px 20px 0px 20px;
    width:100vw;
    height:100vh;
    /* overflow:auto; */
  }

  .Portions_covered_Text_Container {
    width: 45%;
  }

  .Container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 60px 0px 60px;
  }

   .BookOpen_Icon {
    width:14px;
    height:14px;
  }

  .Manoj_span {
    font-size: 12px;
  }

  .myProgress {
    width:80% !important;
  }

}

@media (max-width: 1010px) {

  /* Assessment  */
    .assessment_container_questionalignment_skdjh{
      margin-left: 20px;
    }
    .assessment_continer_align_dnjkdd{
      justify-content: flex-start !important;

    }
    .assessment_continer_taketestfont_dnjkdd{
      font-size: 13px !important;
    }
    .assessment_container_questaketest_skdjh{
      background: rgb(76, 136, 255);
      border-radius: 0px 0px 10px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: max-content !important;
      padding: 8px 20px !important;
      border-radius: 18px !important;
      position: absolute !important;
      bottom: 10px !important;
      left: 10px;
    }
    .assessment_container_card_skdjh{
      flex-direction :column !important;
    }
    .assessment_container_card_skdjh_list{
      width:100% !important;
      position: relative;
    }
    /* Assessment End */
  }

@media only screen and (min-device-width: 280px) and (max-device-width: 908px) and (orientation:portrait) {
 /* Assessment */
  .assessment_continer_align_dnjkdd{
    justify-content: flex-start !important;
    margin-left: 20px;
  }
  .assessment_continer_taketestfont_dnjkdd{
    font-size: 13px !important;
}
  .assessment_container_questionalignment_skdjh{
    margin-left: 20px;
  }
  .assessment_container_questaketest_skdjh{
    background: rgb(76, 136, 255);
    border-radius: 0px 0px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: max-content !important;
    padding: 8px 20px !important;
    border-radius: 18px !important;
    position: absolute !important;
    bottom: 10px !important;
    left: 10px;
  }

  .assessment_container_card_skdjh{
    flex-direction :column !important;
  }
  .assessment_container_card_skdjh_list{
    width:100% !important;
    position: relative;
  }
  /* Assessment End */
}


/* Responsive design for Tablet  Kalaichelvan */
